import React from 'react';
import Tage from "./Tage";
import "./Public.css";
import EmptyNavBar from "./components/navbar/EmptyNavBar";
import Footer from "./components/Footer";
import {Route, Switch} from "react-router";
import About from "./components/public/About";
import NewPassword from "./components/public/NewPassword";
import Signup from "./components/public/Signup";
import IdleTimer from 'react-idle-timer'
import LoggedOutModal from "./LoggedOutModal";
import SignupPage from "./components/signup/SignupPage";
import ErrorBoundary from "./infra/ErrorBoundary";
import FrontPage from "./components/public/FrontPage";
import RecoverNewPassword from "./components/public/RecoverNewPassword";
import TranslationService from "./infra/TranslationService";
import {logout} from "./infra/Logout";
import CountersignForm from "./components/countersign/v2/CountersignForm";
import FrontPageAbout from "./components/public/FrontPageAbout";
import background from "./images/frontpage-background.jpg"
import LoginMobile from "./components/public/LoginMobile";
import {changeLayoutWidth, DESKTOP, MOBILE} from "./infra/Constants";
import Loading2 from "./infra/Loading2";
import {BackendContext, BackendInterface} from "./infra/BackendContext";
import {BackendHandler} from "./BackendHandler";
import GlobalToaster from "./GlobalToaster";
import PublicDeviation from "./components/deviation/PublicDeviation";
import {unsecuredPost} from "./infra/BackendService";
import Replenishment from "./components/replenishment/Replenishment";
import CacheBuster from "./CacheBuster";
import {UserData} from "./components/model/UserData";
import {Organisation} from "./components/model/Organisation";
import {NavBar} from "./components/model/NavBar";
import {User} from "./components/model/User";
import {selectDefaultOrganisation} from "./components/organisation/SelectOrganisation";

const timeout = 120; // minutes
const allowedIdleTime = 30 * 60 * 1000; // milliseconds

export function getDomain(host: any) {
    const parts = host.split('.');
    const length = parts.length;
    if (length > 0) {
        return parts[length - 1];
    }

    return "se";
}

interface props {
    testing?: boolean
}

interface state {
    userData: UserData,
    loggedIn: boolean,
    afk: boolean,
    afkTimer: number,
    graceTimer: boolean,
    externallyLogged: boolean,
    currentlyLoading: boolean,
    layout: any,
    successText: string | null;
    errorText: string | null;
    displaySuccess: boolean;
    displayError: boolean;
    path: string;
    currentOrganisation: Organisation | undefined,
    organisationSelected: boolean;
    navBarAvailable: boolean
}

class Public extends React.Component<props, state> {
    private idleTimer: any;
    private readonly onAction: OmitThisParameter<(e: any) => void>;
    private readonly onActive: OmitThisParameter<(e: any) => void>;
    private readonly onIdle: OmitThisParameter<(e: any) => void>;

    private readonly backendInterface: BackendInterface;

    constructor(props: props) {
        super(props);

        this.setUserData = this.setUserData.bind(this);
        this.loggedIn = this.loggedIn.bind(this);
        this.refreshToken = this.refreshToken.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
        this.setExternalLogout = this.setExternalLogout.bind(this);
        this.removeExternalLogout = this.removeExternalLogout.bind(this);
        this.gracePeriodResponse = this.gracePeriodResponse.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.updateNavBar = this.updateNavBar.bind(this);
        this.setCurrentOrganisation = this.setCurrentOrganisation.bind(this);
        this.fetchingOrganisation = this.fetchingOrganisation.bind(this);

        this.idleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);

        this.onResize = this.onResize.bind(this);

        let startingState = {
            userData: {
                securityToken: '',
                userName: '',
                user: {
                    userName: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    dateFormat: '',
                    locale: '',
                    userIdentifiers: [],
                    preferences: '',
                    rights: [],
                    organisations: []
                },
                navBar: {
                    brand: {
                        translatedName: '',
                        route: '',
                        subList: []
                    },
                    items: [],
                    organisations: undefined
                }
            },
            loggedIn: false,
            afk: false,
            afkTimer: 0,
            graceTimer: false,
            externallyLogged: false,
            currentlyLoading: false,
            layout: MOBILE,
            successText: null,
            errorText: null,
            displayError: false,
            displaySuccess: false,
            path: "#/",
            currentOrganisation: undefined,
            organisationSelected: false,
            navBarAvailable: false
        };
        this.state = startingState;

        let userDataLocal = sessionStorage.getItem("userData");
        if (!userDataLocal) {
            this.state = {...startingState, loggedIn: false}
        } else {
            const userData: UserData = JSON.parse(userDataLocal);
            this.state = {...startingState, userData: userData}
        }

        let externallyLogged = !!sessionStorage.getItem("inactivityLogout");
        if (externallyLogged) {
            this.state = {...startingState, externallyLogged: true}
            sessionStorage.removeItem("inactivityLogout");
        }

        this.backendInterface = BackendHandler.getHandler(this.succeed, this.failure);
    }

    _onAction(e: any) {
        if (e.type === "visibilitychange" && document.visibilityState === 'visible') {
            let difference = e.timeStamp - this.state.afkTimer;
            this.setState({afkTimer: 0});
            if ((difference >= allowedIdleTime) && this.state.loggedIn) {
                this.setExternalLogout();
                this.logoutUser();
            }
        } else if (e.type === "visibilitychange" && document.visibilityState === 'hidden') {
            this.setState({afkTimer: e.timeStamp});
        }
    }

    _onActive(e: any) {
        console.log("_onActive", e);
    }

    _onIdle(e: any) {
        console.log("_onIdle", e);
        if (this.state.loggedIn) {
            this.setState({graceTimer: true});

            setTimeout(() => {
                if (this.state.graceTimer) {
                    this.setExternalLogout();
                    this.logoutUser();
                }
            }, allowedIdleTime);
        }
    }

    setExternalLogout() {
        sessionStorage.setItem("inactivityLogout", "true");
    }

    logoutUser(reason?: string) {
        console.log("Logout reason: ", reason);
        logout();
    }

    private setUserData(userData: UserData) {
        this.setState(
            {userData: userData}, () => {
                const organisationSelected: boolean = this.state.organisationSelected;
                const navBarAvailable: boolean = this.state.navBarAvailable;

                if (organisationSelected && !navBarAvailable) {
                    this.selectDefaultOrganisation();
                }
            }
        );
    }

    private selectDefaultOrganisation(): void {
        const currentOrganisation: Organisation | undefined = this.state.currentOrganisation;
        const user = this.state.userData.user;

        const updateNavBar = this.updateNavBar;
        const setCurrentOrganisation = this.setCurrentOrganisation;
        const post = this.backendInterface.post;
        const fetchingOrganisation = this.fetchingOrganisation;

        selectDefaultOrganisation(
            currentOrganisation,
            user,
            updateNavBar,
            setCurrentOrganisation,
            post,
            fetchingOrganisation
        );
    }

    private refreshToken(token: string) {
        let newUserData = this.state.userData;
        newUserData.securityToken = token;
        this.setState({userData: newUserData});
    }

    private loggedIn() {
        this.setState({loggedIn: true},
            async () => {
                // When we have selected an organisation, we will not route the user through
                // the organisation selector and therefore not fetch the translations

                const hasSelectedOrganisation: boolean = this.state.organisationSelected;
                if (hasSelectedOrganisation && !this.props.testing) {
                    await TranslationService.init();
                }
            });
    }

    private readonly removeExternalLogout = () => {
        this.setState({externallyLogged: false});
    };

    private readonly gracePeriodResponse = () => {
        this.setState({graceTimer: false});
    };

    private readonly setLoading = (wantedState: any) => {
        this.setState({currentlyLoading: wantedState});
    }

    componentDidMount() {
        this.onResize();
        window.addEventListener('resize', this.onResize);

        const path = window.location.hash;
        this.handleWellKnownURLs(path);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    private onResize() {
        const width = window.innerWidth;
        const layout = this.state.layout;

        if (width > changeLayoutWidth && layout === MOBILE) {
            this.setState({
                layout: DESKTOP
            });
        }

        if (width <= changeLayoutWidth && layout === DESKTOP) {
            this.setState({
                layout: MOBILE
            });
        }
    }

    private handleWellKnownURLs(path: string) {
        if (path.includes("/fjn/")) {
            this.handleDrugBookRequest(path);
        } else if (path.includes("/countersign")) {
            this.handleCountersignRequest(path);
        } else {
            this.handleUnknownRequest();
        }
    }

    private handleUnknownRequest() {
        this.setState({
            path: '#/'
        });
    }

    private handleCountersignRequest(path: string) {
        this.setState({
            path: path,
        });
    }

    private handleDrugBookRequest(path: string) {
        const splitPath: string[] = path.split('/');
        const url: string = "/api/v1/knarkbok/organisations/qr"
        const payload: { qr: string } = {qr: splitPath[splitPath.length - 1]}

        const drugbookOrganisation: Promise<Organisation> = unsecuredPost(payload, url);
        drugbookOrganisation.then((organisation: Organisation) => {
            if (organisation) {
                this.setState({
                    path: path,
                    currentOrganisation: organisation,
                    organisationSelected: true
                });
            } else {
                this.setState({
                    path: '#/',
                    currentOrganisation: undefined,
                    organisationSelected: false
                });
                window.location.hash = '#/';
            }
        }).catch(() => {
            this.setState({
                path: '#/',
                currentOrganisation: undefined,
                organisationSelected: false
            });
            window.location.hash = '#/';
        });
    }

    render(): React.JSX.Element {
        const inactivityMessage = TranslationService.translation("logout after inactivity");
        const aboutToBecomeInactive = TranslationService.translation("about to logout after inactivity");
        const ok = TranslationService.translation("ok");
        const noThankYou: string = TranslationService.translation("no thank you");
        let content;
        const loggedIn: boolean = this.state.loggedIn;
        const userData: UserData = this.state.userData;
        const hasSelectedOrganisation: boolean = this.state.organisationSelected;

        let testing: boolean = false;
        if (this.props.testing) {
            testing = true;
        }

        if (loggedIn) {
            const user: User = userData.user;
            const securityToken: string = userData.securityToken;
            const refreshToken = this.refreshToken;
            const logoutUser = this.logoutUser;
            const refreshThreshold: number = 1;
            const currentOrganisation: Organisation | undefined = this.state.currentOrganisation;
            content = <Tage user={user}
                            updateNavBar={this.updateNavBar}
                            setCurrentOrganisation={this.setCurrentOrganisation}
                            userData={userData}
                            currentOrganisation={currentOrganisation}
                            hasSelectedOrganisation={hasSelectedOrganisation}
                            logoutUser={logoutUser}
                            refreshToken={refreshToken}
                            refreshThreshold={refreshThreshold}
                            securityToken={securityToken}
                            setPath={this.setPath}
                            testing={testing}
                            loggedIn={loggedIn}
            />;
        } else {
            content = this.drawPublicContent();
        }

        let loadingSpinner;
        if (this.state.currentlyLoading) {

            let loading2 = <div></div>;
            if (!testing) {
                loading2 = <Loading2/>;
            }
            loadingSpinner = <div className={"loading-canvas-background"}>
                <h4 className={"loading-text text-center mt-4"}>Loading...</h4>
                <canvas className={"loading-canvas"}>
                    <p>Loading balls</p>
                </canvas>
                {loading2}
            </div>;
        } else {
            loadingSpinner = <div/>;
        }

        return (
            <ErrorBoundary userData={userData} path={this.state.path}>
                <BackendContext.Provider value={this.backendInterface}>
                    {loadingSpinner}
                    <div className={"wholeSite"}>
                        <IdleTimer
                            ref={ref => {
                                this.idleTimer = ref
                            }}
                            element={document}
                            onActive={this.onActive}
                            onIdle={this.onIdle}
                            onAction={this.onAction}
                            debounce={250}
                            timeout={timeout * 60 * 1000}/>
                        {content}
                        {
                            this.state.externallyLogged ? <LoggedOutModal buttonText={ok}
                                                                          onRespond={this.removeExternalLogout}
                                                                          textField={inactivityMessage}/> : ""
                        }
                        {
                            this.state.graceTimer ? <LoggedOutModal buttonText={noThankYou}
                                                                    onRespond={this.gracePeriodResponse}
                                                                    textField={aboutToBecomeInactive}/> : ""
                        }
                    </div>
                </BackendContext.Provider>
                <GlobalToaster displayError={this.state.displayError}
                               displaySuccess={this.state.displaySuccess}
                               errorText={this.state.errorText}
                               successText={this.state.successText}
                               closeToast={this.closeToast}/>
            </ErrorBoundary>
        );
    }

    private updateNavBar(navBar: NavBar): void {
        const userData: UserData = this.state.userData;
        userData.navBar = navBar;

        this.setState({
            userData: userData,
            navBarAvailable: true
        }, () => {
            window.location.hash = this.state.path
        });
    }

    private setCurrentOrganisation(organisation: Organisation | undefined): void {
        this.setState({
            currentOrganisation: organisation,
            organisationSelected: true
        });
    }

    private fetchingOrganisation(fetchingOrganisation: boolean): void {
        this.setState({
            currentlyLoading: fetchingOrganisation
        });
    }

    drawPublicContent() {
        const domain = getDomain(window.location.hostname);
        const path: string = window.location.hash;
        const width: number = window.innerWidth - 200;

        let login: any;
        const layout = this.state.layout;
        if (layout === MOBILE) {
            login = <LoginMobile loggedIn={this.loggedIn}
                                 setUserData={this.setUserData}
                                 setLoading={this.setLoading}/>;
        } else {
            login = <div/>;
        }

        if (path.includes("public-countersign")) {
            return <div>
                <EmptyNavBar loggedIn={this.loggedIn}
                             setUserData={this.setUserData}
                             setLoading={this.setLoading}
                             layout={layout}/>
                <Route path="/public-countersign/:countersignId" component={CountersignForm}/>
            </div>
        }

        if (path.includes("/public/deviation/edbb9670-a6fe-4ba8-ac03-60fc93d410ad")) {
            return <PublicDeviation site={"Kontoret i Mörby"}
                                    unsecuredPost={unsecuredPost}
            />;
        }

        if (path.includes('/public/replenishment')) {
            return <Replenishment site={'Förrådet i Västberga'}
            />;
        }

        let fragment = <React.Fragment>
            <EmptyNavBar loggedIn={this.loggedIn}
                         setUserData={this.setUserData}
                         setLoading={this.setLoading}
                         layout={layout}/>
            <Switch>
                <Route path="/#/" exact
                       render={() => login}
                />

                <Route path="/login" exact
                       render={() => login}
                />

                <Route path="/about" render={() => <About/>}/>
                <Route path="/signup" exact render={() => <Signup inheritedFetch={this.inheritedFetch}/>}/>
                <Route path="/signupPage/:token" component={SignupPage}/>
                <Route path="/recovery/new" component={RecoverNewPassword}/>
                <Route path="/expired-password" component={RecoverNewPassword}/>
                <Route path="/new-password/:token" component={NewPassword}/>

                <Route path="/new-front-page" exact
                       render={() => <FrontPage loggedIn={this.loggedIn}
                                                setUserData={this.setUserData}
                                                setLoading={this.setLoading}/>
                       }/>

                <Route render={() => login}/>

            </Switch>

            <div className="text-center image-mid-container">
                <img className="background-image img-fluid"
                     width={width}
                     src={background}
                     alt={"background"}/>
            </div>

            <FrontPageAbout domain={domain}/>
            <Footer/>
        </React.Fragment>;
        return <CacheBuster content={fragment}>
        </CacheBuster>
    }

    inheritedFetch = (url: string, fetchObject: { method: string, body: string }) => {
        return fetch(url, fetchObject);
    }


    // unclear what these should do when given an empty text string
    succeed = (text?: string): void => {
        if (text) {
            this.setState({
                displaySuccess: true,
                successText: text
            })
        } else {
            this.setState({
                displaySuccess: false
            })
        }
    }

    failure = (text?: string): void => {
        if (text) {
            this.setState({
                displayError: true,
                errorText: text
            })
        } else {
            this.setState({
                displayError: false,
                errorText: ""
            })
        }

    }

    setPath = (path: string): void => {
        this.setState({path: path});
    }

    closeToast = () => {
        this.setState({displaySuccess: false, displayError: false})
    }
}

export default Public;
