import React from "react";
import {Action, Field} from "../../register/v2/Action";
import {AgeFilterSelection, GraphOptions} from "../v2/GraphOptions";
import Checkbox from "../../fields/v2/Checkbox";
import {ALL_ALTERNATIVES, DESKTOP} from "../../../infra/Constants";
import AgeFilter from "../v2/AgeFilter";
import Event, {getValue} from "../../register/v3/Event";
import StatisticsNumberField from "./StatisticsNumberField";
import {hasRight} from "../../../utils/HasRight";

export default function getField(field: Field,
                                 graphOptions: GraphOptions,
                                 event: Event,
                                 duplicationIndex: string,
                                 action: Action,
                                 user: any,
                                 onValueChange: (name: string, value: (string | string[]), duplicationIndex: string, valid: boolean, field: Field) => void,
                                 onAgeSelectionChange?: (ageSelection: AgeFilterSelection) => void): React.ReactFragment {

    const fieldName = field.name;
    const fieldType = field.type;
    const frontendVersion = action.frontendVersion;
    const key = fieldName + "-" + duplicationIndex;

    if (fieldType !== undefined && fieldType === 'age') {
        const ageSelections: AgeFilterSelection[] | undefined = graphOptions.ageSelection;

        let ageSelection: AgeFilterSelection | undefined = undefined;
        if (ageSelections !== undefined) {
            ageSelection = ageSelections.find((ags: AgeFilterSelection) => ags.name === fieldName);
        }

        if (onAgeSelectionChange !== undefined) {
            return <AgeFilter key={key}
                              field={field}
                              name={fieldName}
                              frontendVersion={frontendVersion}
                              device={DESKTOP}
                              ageSelection={ageSelection}
                              onAgeSelectionChange={onAgeSelectionChange}
            />
        } else {
            throw new Error('\'onAgeSelectionChange\' must be defined');
        }
    }


    if (hasRight(user, "backoffice", "backoffice")) {
        if (field.type === "number") {
            return <StatisticsNumberField field={field} onChange={onValueChange}/>
        }
    }

    if (field.options === undefined) {
        return <div aria-label={'empty label for ' + fieldName}/>;
    }

    const value: string | string[] | undefined = getValue(fieldName, event, '0');
    let currentValue: string[] = [];
    if (value === undefined) {
        currentValue.push(fieldName + '.' + ALL_ALTERNATIVES);
    } else if (typeof value === 'string') {
        currentValue.push(value);
    } else if (Array.isArray(value)) {
        currentValue = value;
    }

    return <Checkbox key={key}
                     field={field}
                     device={DESKTOP}
                     frontendVersion={frontendVersion}
                     value={currentValue}
                     duplicationIndex={duplicationIndex}
                     onChange={onValueChange}
                     event={event}
                     enableAllOptions={true}
                     action={action}
                     user={user}
    />
}
