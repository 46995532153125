import React from "react";
import TranslationService from "../../../infra/TranslationService";
import "./StatisticsNumberFields.css"
import {Field} from "../../register/v2/Action";

export const max_boundary = `fitymi_max_boundary`;
export const min_boundary = `fitymi_min_boundary`;

interface props {
    field: Field
    onChange: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void
}

interface state {
    upperBound: number | undefined
    lowerBound: number | undefined
    warningText: string
}

class StatisticsNumberField extends React.Component<props, state> {
    constructor(props: Readonly<props>) {
        super(props);

        this.state = {
            lowerBound: this.props.field.min,
            upperBound: this.props.field.max,
            warningText: "",
        }
    }

    render() {
        const state = this.state;

        return <div className={"container m-0 p-0"}>
            <div className={"col m-0 p-0"}>
                <div className={"row m-0 pt-2"}>
                    <div className={"col m-0 p-0"}>
                        {TranslationService.translation(this.props.field.name) + ":"}
                    </div>
                </div>
                <div className={"row m-0 pt-1"}>

                    <input aria-label={this.props.field.name+".fitymi_min_boundary"} type={"text"} size={3} value={state.lowerBound} onChange={(e) => this.updateLowerBound(e)}/>
                    <div
                        className={"pt-1 pr-2 middle-explanation-box"}>{ '≤'} x { '≤' }</div>
                    <input type={"text"} size={3} value={state.upperBound} onChange={(e) => this.updateUpperBound(e)}/>
                </div>
            </div>
        </div>;
    }


    private updateProps() {
        const {lowerBound, upperBound} = this.state;
        const { duplicationIndex, name} = this.props.field;


        const values: string[] = [
            `${name}.${min_boundary}.${lowerBound}`,
            `${name}.${max_boundary}.${upperBound}`,
        ]

        this.props.onChange(this.props.field.name, values, '' + duplicationIndex, true, this.props.field)
    }

    private async updateLowerBound(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = +e.currentTarget.value;
        this.setState({lowerBound: newValue}, () => this.updateProps());
    }

    private async updateUpperBound(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = +e.currentTarget.value;
        this.setState({upperBound: newValue}, () => this.updateProps());
    }
}

export default StatisticsNumberField;
