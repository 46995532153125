import React, {ReactElement} from "react";
import {AgeFilterSelection, GraphOptions} from "./GraphOptions";
import {Action, Field} from "../../register/v2/Action";
import TranslationService from "../../../infra/TranslationService";
import getField from "./FieldFactory";
import Event, {createEvent, setValue} from "../../register/v3/Event";
import {ALL_ALTERNATIVES} from "../../../infra/Constants";
import Value from "../../register/v2/Value";

interface props {
    action: Action,
    user: any,
    graphOptions: GraphOptions,
    onAgeChange: () => void,
    onValueChange: (name: string, value: string | string[], duplicationIndex: string, valid: boolean, field: Field) => void,
    onAgeSelectionChange: (ageSelection: AgeFilterSelection) => void
}

interface state {
    action: Action,
    unwantedFieldTypes: Set<string>
}

class Filter extends React.Component<props, state> {

    constructor(props: props) {
        super(props);

        const action: Action = JSON.parse(JSON.stringify(this.props.action));
        const unwantedFieldTypes: Set<string> = new Set();
        unwantedFieldTypes.add('comment');
        unwantedFieldTypes.add('age');

        this.state = {
            action: action,
            unwantedFieldTypes: unwantedFieldTypes
        }
    }

    render(): ReactElement {
        const action: Action = this.state.action;
        const actionName: string = action.name;
        const graphOptions: GraphOptions = this.props.graphOptions;
        const event: Event = this.getEvent(action, graphOptions);
        const user = this.props.user;
        const headline: string = TranslationService.translation('filter');
        const filter: React.ReactFragment = this.getFilter(action, graphOptions, event, user);

        return <div key={actionName}
                    aria-label={"statistics filter v3"}>
            <hr aria-label={'horizontal row'}/>
            <h3>{headline}</h3>
            {filter}
        </div>
    }

    private getFilter(action: Action, graphOptions: GraphOptions, event: Event, user: any): React.JSX.Element {
        const unwantedFieldTypes = this.state.unwantedFieldTypes;
        const fields: Field [] = action.fields;
        const filter: React.JSX.Element[] = fields.map((field: Field) => {
            const duplicationIndex: string = '' + field.duplicationIndex;
            const name: string = field.name;
            const key: string = this.getKey(field);
            const translated: string = TranslationService.translation(name);

            if (field.type !== undefined) {
                if (unwantedFieldTypes.has(field.type)) {
                    return <div key={key}/>
                }
            }

            const heading: React.ReactFragment = <>
                <hr aria-label={'horizontal row'}/>
                <h4>{translated}</h4>
            </>;

            const visibleField: React.ReactFragment = this.renderFields(field, graphOptions, event, duplicationIndex, user, action);

            return <div key={key}>
                {heading}
                {visibleField}
            </div>
        });

        return <div aria-label={'filter'}>
            {filter}
        </div>;
    }

    private renderFields(field: Field, graphOptions: GraphOptions, event: Event, duplicationIndex: string, user: any, action: Action): React.ReactFragment {
        const key = this.getKey(field);

        if (field.fields === undefined || field.fields.length === 0) {
            const fieldInstances: ({} | React.ReactNodeArray)[] = [];

            return this.renderField(field, graphOptions, event, duplicationIndex, key, user, action, fieldInstances);
        } else {
            const fieldInstances = this.getFieldInstances(field, graphOptions, event, duplicationIndex, user, action);

            return this.renderField(field, graphOptions, event, duplicationIndex, key, user, action, fieldInstances);
        }
    }

    private renderField(field: Field,
                        graphOptions: GraphOptions,
                        event: Event,
                        duplicationIndex: string,
                        key: string,
                        user: any,
                        action: Action,
                        fieldInstances: React.ReactFragment[]): React.JSX.Element {
        const onValueChange = this.props.onValueChange;
        const onAgeSelectionChange = this.props.onAgeSelectionChange;
        let fieldInstance: React.ReactFragment;

        const unwantedFieldTypes = this.state.unwantedFieldTypes;
        if (field.type !== undefined) {
            if (unwantedFieldTypes.has(field.type)) {
                return <div key={key}/>
            }
        }

        fieldInstance = getField(field, graphOptions, event, duplicationIndex, action, user, onValueChange, onAgeSelectionChange);

        return <div aria-label={field.name}
                    key={key}>
            {fieldInstance}
            {fieldInstances}
        </div>;
    }

    private getFieldInstances(field: Field,
                              graphOptions: GraphOptions,
                              event: Event,
                              duplicationIndex: string,
                              user: any,
                              action: Action): React.ReactFragment[] {
        let fieldInstances: React.ReactFragment[] = [];

        if (field.fields !== undefined) {
            const fields: Field[] = field.fields;
            fieldInstances = fields.map((f: Field) => {
                return this.renderFields(f, graphOptions, event, duplicationIndex, user, action);
            });
        }

        return fieldInstances;
    }

    private getKey(field: Field) {
        return field.name + '-' + field.duplicationIndex;
    }

    private getEvent(action: Action, graphOptions: GraphOptions): Event {
        let event: Event = createEvent();
        if (graphOptions.values !== undefined) {
            graphOptions.values.forEach((value: Value) => {
                let name = value.fieldName;
                const values = value.values;
                let field: Field | undefined = this.getField(name, action);
                if (field === undefined) {
                    return event;
                }

                if (field.type !== "number") { // this should be a skiplist
                    field.type = 'checkbox'; //is this strictly necessary?
                }

                event = setValue(name, values, '0', true, field, event);

                values.forEach((name: string) => {
                    field = this.getField(name, action);
                    if (field !== undefined) {
                        field.type = 'checkbox'; //is this strictly necessary?
                        const value: string[] = [name + '.' + ALL_ALTERNATIVES];
                        event = setValue(name, value, '0', true, field, event);
                    }
                });
            });
        }

        return event;
    }

    private getField(wantedFieldName: string, action: Action): Field | undefined {
        return this.searchField(wantedFieldName, action.fields);
    }

    private searchField(wantedFieldName: string, fields: Field[]): Field | undefined {
        for (let i: number = 0; i < fields.length; i++) {
            const field: Field = fields[i];
            field.conditionalOn = undefined;
            if (field.name === wantedFieldName) {
                return field;
            } else {
                if (field.fields !== undefined) {
                    const found: Field | undefined = this.searchField(wantedFieldName, field.fields);
                    if (found !== undefined) {
                        return found;
                    }
                }

                if (field.options !== undefined) {
                    const options: Field[] = [];
                    for (let j: number = 0; j < field.options.length; j++) {
                        const option: string | Field = field.options[j];
                        if (typeof option !== 'string') {
                            option.type = 'checkbox'; //is this strictly necessary?
                            options.push(option);
                        }
                    }
                    const found: Field | undefined = this.searchField(wantedFieldName, options);
                    if (found !== undefined) {
                        return found;
                    }
                }
            }
        }

        return undefined;
    }
}

export default Filter;
