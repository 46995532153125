import Value from "../../register/v2/Value";
import {ALL_ALTERNATIVES} from "../../../infra/Constants";
import {Organisation} from "../../model/Organisation";

export interface AgeFilterSelection {
    'name': string,
    'from'?: number,
    'to'?: number,
    'unit': string
}

export interface GraphOptions {
    groupBy: string,
    currentOrganisation?: Organisation
    graphType: string,
    movingAverage?: number | undefined,
    periodSelection?: {
        periodFrom: string,
        periodTo: string
    },
    lastSelection?: {
        amount: number
    },
    firstSelection?: {
        amount: number
    },
    ageSelection?: AgeFilterSelection[],
    values?: Value[]
}


export function addValueToGraphOptions(value: string | string[], name: string, graphOptions: GraphOptions) {
    let values: string[] = [];
    if (Array.isArray(value)) {
        values = value.filter((val) => !val.endsWith((ALL_ALTERNATIVES)));
    } else {
        if (!value.endsWith(ALL_ALTERNATIVES)) {
            values.push(value);
        }
    }

    const newValue: Value = {
        fieldName: name,
        values: values
    };


    if (graphOptions.values === undefined) {
        graphOptions.values = [];
        if (newValue.values.length > 0) {
            graphOptions.values.push(newValue);
        }
    } else {
        const updatedValues: Value[] = graphOptions.values.filter((v: Value) => v.fieldName !== name);
        if (newValue.values.length > 0) {
            updatedValues.push(newValue);
        }
        graphOptions.values = updatedValues;
    }

    return graphOptions;
}
